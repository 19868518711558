import React from 'react'
import { navigate } from 'gatsby'
import { getSiteData } from '../utils/site'
import { getHuntStatus } from '../utils/team-data'
import LoggedInWrapper from '../components/loggedinWrapper'
import Slider from 'react-slick'
import AppContext from '../utils/context'
import { getCurrentHuntData } from '../utils/hunt-data'

export default class RulesPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      data: {},
      how_to_play_heading: `How to Play`,
      hunt_status: `pending`,
    }
    this.firstClientX = 0
    this.sliderContainer = React.createRef()
    this.preventTouch = this.preventTouch.bind(this)
    this.touchStart = this.touchStart.bind(this)
    this.getRuleSlides = this.getRuleSlides.bind(this)
    this.nextSlide = this.nextSlide.bind(this)
    this.readRules = this.readRules.bind(this)
    this.showSettingsTooltip = this.showSettingsTooltip.bind(this)
  }

  componentDidMount() {
    this.sliderContainer.current.addEventListener(`touchstart`, this.touchStart)
    this.sliderContainer.current.addEventListener(`touchmove`, this.preventTouch, { passive: false })
    getSiteData().then(data => {
      if (data.how_to_play_heading !== null) {
        this.setState({
          how_to_play_heading: data.how_to_play_heading,
        })
      }
      let hunt_status = getHuntStatus()
      const huntData = getCurrentHuntData()
      this.setState({ hunt_status: hunt_status, data: huntData })
      this.setLoading(false)
    })

    // show settings tooltip after 5 seconds
    setTimeout(this.showSettingsTooltip, 5000)
  }

  showSettingsTooltip() {
    this.setSettingsTooltip(true)
  }

  componentWillUnmount() {
    this.sliderContainer.current.removeEventListener(`touchstart`, this.touchStart)
    this.sliderContainer.current.removeEventListener(`touchmove`, this.preventTouch, {
      passive: false,
    })
  }

  readRules() {
    // console.log('updating rules as read')
    if (this.state.hunt_status == `pending`) {
      navigate(`/start/`)
    } else {
      navigate(`/`)
    }
  }

  getRuleSlides() {
    let rule_slides = []
    if (this.state.data.hunt !== undefined) {
      rule_slides = this.state.data.hunt.rules.split(`---`)
    }
    return rule_slides
  }

  nextSlide() {
    this.slider.slickNext()
    window.scrollTo(0, 0) // scroll back to the top when going to the next slide programmatically
  }

  touchStart(e) {
    this.firstClientX = e.touches[0].clientX
  }

  preventTouch(e) {
    const minValue = 5 // threshold

    let diff = e.touches[0].clientX - this.firstClientX

    // Vertical scrolling does not work when you start swiping horizontally.
    if (Math.abs(diff) > minValue) {
      // e.preventDefault()
      e.returnValue = false
      return false
    }
  }

  render() {
    const { data, hunt_status, how_to_play_heading } = this.state
    const rule_slides = this.getRuleSlides()
    var sliderSettings = {
      arrows: false,
      dots: true,
      infinite: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      adaptiveHeight: true,
    }

    return (
      <AppContext.Consumer>
        {({ setSettingsTooltip, setLoading }) => {
          this.setSettingsTooltip = setSettingsTooltip
          this.setLoading = setLoading
          return (
            <LoggedInWrapper>
              <h1 className="sr-only">Rules</h1>
              <div ref={this.sliderContainer} className="mt-3">
                {data !== {} && data.hunt !== undefined ? (
                  <Slider className={`rules-slider mb-3`} ref={slider => (this.slider = slider)} {...sliderSettings}>
                    {rule_slides.map((slide, index) => (
                      <div key={`slide-${index}`}>
                        <div className={`card full-height mx-4`}>
                          <div className="card-header text-center">
                            <h3>{how_to_play_heading}</h3>
                          </div>
                          <div className="card-body pb-3 flex-column justify-content-between card-body--haslinks">
                            <div className="card-text" dangerouslySetInnerHTML={{ __html: slide }} />
                            {hunt_status !== `ended` && index == rule_slides.length - 1 ? (
                              <button className={`btn btn-secondary btn-block btn-arrow`} onClick={this.readRules}>
                                Next
                              </button>
                            ) : (
                              <button className={`btn btn-secondary btn-block btn-arrow`} onClick={this.nextSlide}>
                                Next
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    ))}
                  </Slider>
                ) : (
                  <p>Loading...</p>
                )}
              </div>
            </LoggedInWrapper>
          )
        }}
      </AppContext.Consumer>
    )
  }
}
